<template>
  <div class="uk-width-1-2 uk-padding-small">
    <ul
      uk-accordion
      v-if="['storefront-administrator'].includes(authUser.type.slug)"
    >
      <li
        v-for="file in quote.media.filter(
          (media) => media.collection_name === slug
        )"
        :key="'file-' + file.id"
      >
        <a class="uk-accordion-title uk-text-default uk-link-reset" href="#">
          <div
            class="uk-padding-small uk-border-rounded text-white"
            :class="{
              'file-approved': file.custom_properties.status === 'approved',
              'file-rejected': file.custom_properties.status === 'rejected',
              'file-pending': file.custom_properties.status === 'pending',
            }"
          >
            <div class="uk-flex uk-flex-between">
              <div>{{ file.file_name }}</div>
              <div>{{ file.custom_properties.status }}</div>
            </div>
            <div>Fecha de carga: {{ formatDatetime(file.created_at) }}</div>
            <div
              v-if="file.custom_properties.resolved_at"
              class="uk-margin-top"
            >
              Resolución de Gimmick el
              {{ formatDatetime(file.custom_properties.resolved_at) }}
            </div>
            <div
              v-if="file.custom_properties.resolution_notes"
              class="uk-margin-top"
            >
              Comentarios de Gimmick:
              {{ file.custom_properties.resolution_notes }}
            </div>
          </div>
        </a>
        <div
          class="
            uk-accordion-content
            uk-margin-remove-top
            uk-margin-bottom
            uk-background-muted
            uk-padding-small
          "
        >
          <div class="uk-text-center">
            <a
              :href="file.path"
              target="_blank"
              class="uk-button uk-button-primary uk-border-rounded"
            >
              <span uk-icon="cloud-download"></span>
            </a>
          </div>
          <div class="uk-border-rounded uk-overflow-hidden uk-margin-small-top">
            <textarea
              class="uk-textarea background-white"
              rows="5"
              placeholder="Comentarios de resolución..."
              style="resize: none"
              :ref="'file-resolution-notes-' + file.id"
            ></textarea>
            <div class="uk-button-group uk-width-1-1">
              <button
                class="
                  uk-button uk-width-1-2 uk-padding-remove uk-button-primary
                "
                @click="resolveFile(file.id, 1)"
                uk-icon="check"
              ></button>
              <button
                class="
                  uk-button uk-width-1-2 uk-padding-remove uk-button-danger
                "
                @click="resolveFile(file.id, 0)"
                uk-icon="close"
              ></button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else>
      <a
        v-for="file in quote.media.filter(
          (media) => media.collection_name === slug
        )"
        :key="'file-' + file.id"
        class="uk-link-reset"
        target="_blank"
        :href="file.path"
      >
        <div
          class="
            uk-margin-small-bottom uk-padding-small uk-border-rounded
            text-white
          "
          :class="{
            'file-approved': file.custom_properties.status === 'approved',
            'file-rejected': file.custom_properties.status === 'rejected',
            'file-pending': file.custom_properties.status === 'pending',
          }"
        >
          <div class="uk-flex uk-flex-between">
            <div>{{ file.file_name }}</div>
            <div>{{ file.custom_properties.status }}</div>
          </div>
          <div>Fecha de carga: {{ formatDatetime(file.created_at) }}</div>
        </div>
      </a>
    </div>
    <div uk-form-custom class="uk-width-1-1 dashed-border">
      <input type="file" @change="handleFile($event.target.files[0], slug)" />
      <div class="uk-border-rounded uk-text-center">
        <div>
          <span uk-icon="icon: cloud-upload; ratio: 2"></span>
        </div>
        <div>Cargar {{ name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import { mapGetters } from "vuex";
export default {
  name: "RequiredQuoteFile",

  props: ["name", "slug", "quote"],

  computed: {
    ...mapGetters(["authUser"]),
  },

  methods: {
    handleFile(file, type) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);
      this.axios
        .post(
          "/quotes/resolution/" + this.$route.params.id + "/files",
          formData
        )
        .then(() => {
          UIkit.notification({
            message:
              "<div class='uk-flex uk-flex-middle'><div uk-icon='check'></div><div class='uk-margin-small-left'>Documento cargado</div></div>",
            status: "success",
            pos: "bottom-left",
          });
          this.$parent.fetchResolution();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resolveFile(fileId, approved) {
      this.isLoading = true;
      this.axios
        .post("/quotes/resolve-file/" + fileId, {
          approved,
          resolution_notes:
            this.$refs["file-resolution-notes-" + fileId][0].value,
        })
        .then(() => {
          this.$parent.fetchResolution();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.file-approved {
  background-color: green;
}
.file-rejected {
  background-color: red;
}
.file-pending {
  background-color: orange;
}
</style>