<template>
  <div>
    <div v-if="isLoading" class="uk-text-center">
      <div uk-spinner></div>
    </div>
    <div v-else>
      <div class="uk-text-large">
        Estado de cotización <span class="uk-text-bold">{{ quote.code }}</span>
      </div>
      <div uk-grid class="uk-margin-top">
        <div class="uk-width-1-4">
          <div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-primary
                uk-border-rounded
                uk-padding-small
              "
            >
              <div uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">Cotización generada</div>
            </div>
            <div class="uk-text-center">
              <div uk-icon="icon: arrow-down; ratio: 2;"></div>
            </div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-default
                uk-border-rounded
                uk-padding-small
              "
            >
              <div v-if="false" uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">
                Documentos de aceptación completados
              </div>
            </div>
            <div class="uk-text-center">
              <div uk-icon="icon: arrow-down; ratio: 2;"></div>
            </div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-default
                uk-border-rounded
                uk-padding-small
              "
            >
              <div v-if="false" uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">Confirmación de órden</div>
            </div>
            <div class="uk-text-center">
              <div uk-icon="icon: arrow-down; ratio: 2;"></div>
            </div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-default
                uk-border-rounded
                uk-padding-small
              "
            >
              <div v-if="false" uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">Confirmación de pago</div>
            </div>
            <div class="uk-text-center">
              <div uk-icon="icon: arrow-down; ratio: 2;"></div>
            </div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-default
                uk-border-rounded
                uk-padding-small
              "
            >
              <div v-if="false" uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">Fabricación</div>
            </div>
            <div class="uk-text-center">
              <div uk-icon="icon: arrow-down; ratio: 2;"></div>
            </div>
            <div
              class="
                uk-flex
                uk-flex-middle
                uk-card-default
                uk-border-rounded
                uk-padding-small
              "
            >
              <div v-if="false" uk-icon="icon: check; ratio: 1;"></div>
              <div class="uk-margin-small-left">Entregada</div>
            </div>
          </div>

          <div
            class="uk-margin-top"
            v-if="['storefront-administrator'].includes(authUser.type.slug)"
          >
            <div class="uk-text-large">Correos relacionados</div>
            <ul uk-accordion>
              <li
                v-for="email in quote.emails"
                :key="'related-email-' + email.id"
              >
                <a
                  class="uk-accordion-title uk-text-default uk-link-reset"
                  href="#"
                >
                  <div
                    class="
                      uk-card-secondary uk-padding-small uk-border-rounded
                      text-white
                    "
                  >
                    <div>
                      <div>Destinatario: {{ email.recipient }}</div>
                      <div>Asunto: {{ email.subject }}</div>
                      <div>
                        Enviado el {{ formatDatetime(email.created_at) }}
                      </div>
                    </div>
                  </div>
                </a>
                <div
                  class="
                    uk-accordion-content
                    uk-margin-remove-top
                    uk-margin-bottom
                    uk-background-muted
                    uk-padding-small
                  "
                >
                  <div
                    v-for="event in email.events"
                    :key="'email-event-' + event.id"
                    class="uk-flex uk-flex-middle uk-margin-small-bottom"
                  >
                    <div v-if="event.type === 'opened'" uk-icon="search"></div>
                    <div v-if="event.type === 'delivered'" uk-icon="pull"></div>
                    <div>
                      {{ emailEventTranslations[event.type] }} -
                      {{ formatDatetime(event.timestamp) }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="uk-width-3-4">
          <div>
            <object
              :data="quote.file.path"
              type="application/pdf"
              class="uk-width-1-1 uk-height-large"
            >
              <embed
                :src="quote.file.path"
                type="application/pdf"
                class="uk-width-1-1 uk-height-large"
              />
            </object>
          </div>
          <div class="uk-text-large uk-margin-top">
            Documentos requeridos para aceptar cotización
          </div>
          <div class="uk-flex uk-width-1-1">
            <RequiredQuoteFile
              slug="purchase-orders"
              name="Orden de compra"
              :quote="quote"
            />
            <RequiredQuoteFile slug="artworks" name="Artes" :quote="quote" />
            <RequiredQuoteFile
              slug="proof-of-payment"
              name="Comprobante de pago"
              :quote="quote"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RequiredQuoteFile from "@/views/Utils/RequiredQuoteFile";

export default {
  name: "QuoteResolution",

  components: {
    RequiredQuoteFile,
  },
  data() {
    return {
      quote: null,
      isLoading: true,
      emailEventTranslations: {
        opened: "Abierto",
        delivered: "Entregado",
      },
    };
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  mounted() {
    this.fetchResolution();
  },

  methods: {
    fetchResolution() {
      this.isLoading = true;
      this.axios
        .get("/quotes/resolution/" + this.$route.params.id)
        .then(({ data: { quote } }) => {
          this.quote = quote;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
