var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uk-width-1-2 uk-padding-small"},[(['storefront-administrator'].includes(_vm.authUser.type.slug))?_c('ul',{attrs:{"uk-accordion":""}},_vm._l((_vm.quote.media.filter(
        function (media) { return media.collection_name === _vm.slug; }
      )),function(file){return _c('li',{key:'file-' + file.id},[_c('a',{staticClass:"uk-accordion-title uk-text-default uk-link-reset",attrs:{"href":"#"}},[_c('div',{staticClass:"uk-padding-small uk-border-rounded text-white",class:{
            'file-approved': file.custom_properties.status === 'approved',
            'file-rejected': file.custom_properties.status === 'rejected',
            'file-pending': file.custom_properties.status === 'pending',
          }},[_c('div',{staticClass:"uk-flex uk-flex-between"},[_c('div',[_vm._v(_vm._s(file.file_name))]),_c('div',[_vm._v(_vm._s(file.custom_properties.status))])]),_c('div',[_vm._v("Fecha de carga: "+_vm._s(_vm.formatDatetime(file.created_at)))]),(file.custom_properties.resolved_at)?_c('div',{staticClass:"uk-margin-top"},[_vm._v(" Resolución de Gimmick el "+_vm._s(_vm.formatDatetime(file.custom_properties.resolved_at))+" ")]):_vm._e(),(file.custom_properties.resolution_notes)?_c('div',{staticClass:"uk-margin-top"},[_vm._v(" Comentarios de Gimmick: "+_vm._s(file.custom_properties.resolution_notes)+" ")]):_vm._e()])]),_c('div',{staticClass:"\n          uk-accordion-content\n          uk-margin-remove-top\n          uk-margin-bottom\n          uk-background-muted\n          uk-padding-small\n        "},[_c('div',{staticClass:"uk-text-center"},[_c('a',{staticClass:"uk-button uk-button-primary uk-border-rounded",attrs:{"href":file.path,"target":"_blank"}},[_c('span',{attrs:{"uk-icon":"cloud-download"}})])]),_c('div',{staticClass:"uk-border-rounded uk-overflow-hidden uk-margin-small-top"},[_c('textarea',{ref:'file-resolution-notes-' + file.id,refInFor:true,staticClass:"uk-textarea background-white",staticStyle:{"resize":"none"},attrs:{"rows":"5","placeholder":"Comentarios de resolución..."}}),_c('div',{staticClass:"uk-button-group uk-width-1-1"},[_c('button',{staticClass:"\n                uk-button uk-width-1-2 uk-padding-remove uk-button-primary\n              ",attrs:{"uk-icon":"check"},on:{"click":function($event){return _vm.resolveFile(file.id, 1)}}}),_c('button',{staticClass:"\n                uk-button uk-width-1-2 uk-padding-remove uk-button-danger\n              ",attrs:{"uk-icon":"close"},on:{"click":function($event){return _vm.resolveFile(file.id, 0)}}})])])])])}),0):_c('div',_vm._l((_vm.quote.media.filter(
        function (media) { return media.collection_name === _vm.slug; }
      )),function(file){return _c('a',{key:'file-' + file.id,staticClass:"uk-link-reset",attrs:{"target":"_blank","href":file.path}},[_c('div',{staticClass:"\n          uk-margin-small-bottom uk-padding-small uk-border-rounded\n          text-white\n        ",class:{
          'file-approved': file.custom_properties.status === 'approved',
          'file-rejected': file.custom_properties.status === 'rejected',
          'file-pending': file.custom_properties.status === 'pending',
        }},[_c('div',{staticClass:"uk-flex uk-flex-between"},[_c('div',[_vm._v(_vm._s(file.file_name))]),_c('div',[_vm._v(_vm._s(file.custom_properties.status))])]),_c('div',[_vm._v("Fecha de carga: "+_vm._s(_vm.formatDatetime(file.created_at)))])])])}),0),_c('div',{staticClass:"uk-width-1-1 dashed-border",attrs:{"uk-form-custom":""}},[_c('input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.handleFile($event.target.files[0], _vm.slug)}}}),_c('div',{staticClass:"uk-border-rounded uk-text-center"},[_vm._m(0),_c('div',[_vm._v("Cargar "+_vm._s(_vm.name))])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{attrs:{"uk-icon":"icon: cloud-upload; ratio: 2"}})])}]

export { render, staticRenderFns }